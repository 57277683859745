import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "./Navbar";
import { triggerBase64Download } from 'react-base64-downloader';
import axios from "axios";
export const Posts = () => {
  const [data, setData] = useState([]);

  const fetchData = () => {
    fetch("https://dreams2.onrender.com/posts", {
      // headers: {
      //     "Authorization":localStorage.getItem("token"),
      //   }
    })
      .then((res) => res.json())
      .then((res) => setData(res.posts))

      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  const CopyData=(el)=>{
    const newData={...el,TotalShared:el.TotalShared+1,LastShared:new Date().toISOString()}
    axios.patch(`http://localhost:8080/posts/update/${el._id}`, newData, {
        headers: {
        "content-type": "application/json",
        // Authorization: localStorage.getItem("token"),
        },
        })
        .then((res) => {
        alert(res.data.msg)
        })
    const randomNumber = Math.floor(Math.random() * 9)
    const emojiData=[
        ["😍","🟥","💰","📫"],
        ["🤑","🟠","🪙","📊"],
        ["🤠","🟫","💴","🧰"],
        ["😎","🔵","💵","🗃️"],
        ["💖","🔶","💶","📌"],
        ["💗","💠","💷","📆"],
        ["💞","🔷","💸","💼"],
        ["❤️‍🔥","⚫","💳","📝"],
        ["💜","🔺","💹","📪"],
    ]

    const emoji=emojiData[randomNumber]
    triggerBase64Download(el.image,el.ProductName)
    const text=`${emoji[0]} *${el.DealType}* ${emoji[0]}
    
    ${emoji[1]} *${el.ProductName}* ${emoji[1]}
    
    ${emoji[2]}*Pp: ${el.Price}* ${emoji[2]}
   
   ${emoji[3]}*Refund: ${el.HumDenge}* ${emoji[3]}`
    navigator.clipboard.writeText(text)
   
    
  }
  const handleSearch=(e)=>{
    if(e.target.value==""){
        fetch("https://dreams2.onrender.com/posts", {
            // headers: {
            //     "Authorization":localStorage.getItem("token"),
            //   }
          })
            .then((res) => res.json())
            .then((res) => setData(res.posts))
      
            .catch((err) => console.log(err));
        }
    
    else {
        
        fetch(`https://dreams2.onrender.com/posts/search/${e.target.value}`, {
            // headers: {
            //     "Authorization":localStorage.getItem("token"),
            //   }
          })
            .then((res) => res.json())
            .then((res) => setData(res))
      
            .catch((err) => console.log(err));
    }
    
}
  
  return (
    <div>
      <Navbar />
      <input type="text"
      style={{
        padding: "0.75rem",
        borderRadius: "0.25rem",
        borderWidth: "1px",
        width: "90%",
        marginLeft:"5%",
        boxSizing: "border-box",
        marginBottom: "1rem",
        marginTop:"10px"
      }}
      placeholder="Search Product"
      onChange={(e)=>handleSearch(e)} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        //   minHeight: "100vh",
          
        }}
      >
        <div>
          {/* <div class="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2 max-w-6xl"> */}
          {data?.map((el) => (
            <div
              style={{
                display: "flex",
                padding: "1rem",
                margin: "0.5rem",
                flexDirection: "column",
                borderRadius: "0.5rem",
                backgroundColor: "#E5E7EB",
                width:"100%"
              }}
            >
              <img
                style={{
                  borderRadius: "0.5rem",
                  height: "10rem",
                  backgroundColor: "#9CA3AF",
                }}
                src={el.image}
                alt=""
              />
              <div></div>
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>

                <p
                >
                 Name: {el.ProductName}
                </p>
                <p>Rs. {el.Price}</p>
                </div>
                <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>

                <p
                >
                 Shared- {el.TotalShared} 
                </p>
                <p>Time: {Math.floor((new Date("2024-05-21T19:33:08.594Z") - new Date()) / (1000 * 60 * 60))} hours</p>

                </div>
                <div
                  style={{ display: "flex",justifyContent:"space-around",width:"100%",marginBottom:"10px", gap: "0.75rem", maxWidth: "24rem" }}
                >
                     <button
                    style={{
                      paddingTop: "0.625rem",
                      paddingBottom: "0.625rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      borderRadius: "0.5rem",
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      fontWeight: 500,
                      color: "#ffffff",
                    }}
                  >
                    <Link
                      to={`/update/${el._id}`}
                      style={{ textDecoration: "none", color: "green" }}
                    >
                      View
                    </Link>
                  </button>
                  <button
                    style={{
                      paddingTop: "0.625rem",
                      paddingBottom: "0.625rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      borderRadius: "0.5rem",
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      fontWeight: 500,
                    }}
                  onClick={()=>CopyData(el)}
                  >
                    Share
                  </button>
                 
                </div>
                <div
                  style={{ display: "flex",justifyContent:"space-around",width:"100%", gap: "0.75rem", maxWidth: "24rem" }}
                >
                  <button
                    style={{
                      paddingTop: "0.625rem",
                      paddingBottom: "0.625rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      borderRadius: "0.5rem",
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      fetch(`http://localhost:8080/posts/delete/${el._id}`, {
                        method: "DELETE",
                        headers: {
                          Authorization: localStorage.getItem("token"),
                        },
                      })
                        .then((res) => res.json())
                        .then((res) => {
                          alert(res.msg);
                        })

                        .catch((err) => console.log(err));
                    }}
                  >
                    Delete
                  </button>
                  <button
                    style={{
                      paddingTop: "0.625rem",
                      paddingBottom: "0.625rem",
                      paddingLeft: "1.5rem",
                      paddingRight: "1.5rem",
                      borderRadius: "0.5rem",
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      fontWeight: 500,
                      color: "#ffffff",
                    }}
                  >
                    <Link
                      to={`/update/${el._id}`}
                      style={{ textDecoration: "none", color: "green" }}
                    >
                      Edit
                    </Link>
                  </button>
                </div>
              
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
