import React, { useEffect } from 'react'
import styles from "./skills.module.css"


import Amazon from "../../assets/amazon.jpg"
import Flipkart from "../../assets/flipkart.png"
import Meesho from "../../assets/meesho.png"
import Shoping from "../../assets/shooping.png"
import Rating from "../../assets/rating.jpg"
import Media from "../../assets/socialmedia.jpg"
import Customer from "../../assets/5356723.jpg"
import Products from "../../assets/2660471.jpg"
import Growth from "../../assets/6909932.jpg"
import AOS from 'aos';
import 'aos/dist/aos.css';
const codesandimg = "https://ci.codesandbox.io/static/favicon.ico"

const Skills = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  const SkillsArr = [
    {
      id: 1,
      source: Amazon,
      name: "Amazon",
      colour: "#2C3844",
    },
    {
      id: 2,
      source: Flipkart,
      name: "Flipkart",
      colour: "#2772EF",
    },
    {
      id: 3,
      source: Meesho,
      name: "Meesho",
      colour: "#570344",
    },
  ]
  const Customers = [
    {
      id: 1,
      source: Customer,
      percent:"50+",
      name: "Trusted Customers",
      colour: "#385A64",
    },
    {
      id: 2,
      source: Products,
      percent:"200+",
      name: "Products Boosted",
      colour: "#e44d26",
    },
    {
      id: 3,
      source: Growth,
      percent:"23%",
      name: "Increase In Sells Growth",
      colour: "#007DFE",
    },
  ]

  const ToolsArr = [
    {
    id: 1,
    source: Shoping,
    percent:"95%" ,
    name: "Customers Views Rating And Reviews",
    colour: "#A9E9AF",
  },
    {
    id: 2,
    source: Rating,
    percent:"87%" ,
    name: "Customers Trust Online Ratings Like Personal Recommendations",
    colour: "#FFE04A",
  },
    {
    id: 3,
    source: Media,
    percent:"73%" ,
    name: "Customers Compare Product Ratings Before Deciding on a Purchase",
    colour: "#FDBF90",
  },
]

  return (
    <section className={styles.skill__section} id="skills">
       <div className={styles.skils_toolsdiv}>
        <h3 className={styles.skill_heading}>Why You Need a Positive Image in Ecommerce</h3>
        {/* <p className={styles.skill__description}>Tools I Used</p> */}


        <div className={styles.skills__secondmaindiv}>
          <div className={styles.skills_grid_div}>
            {ToolsArr.map(({ id, source,percent, name, colour }) => (
              <div data-aos="zoom-in-up" className={styles.skills__skilldiv} key={id} style={{ boxShadow: `0 4px 6px -1px ${colour}, 0 2px 4px -2px ${colour}` }}>
                <img style={{width:"99%"}} src={source} alt="skills" />
                
                <p style={{marginBottom:"5px"}}> <span style={{fontWeight:"bolder",fontFamily:"sans-serif",fontSize:"20px"}}>{percent}{" "}</span>{name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* ------------------------------------------ */}
      {/* <p className={styles.skill__description}>Currently optimzing these websites products. </p> */}


      <div data-aos="zoom-in-up" style={{marginTop:"30px"}} className={styles.skills__secondmaindiv}>
      <h3 className={styles.skill_heading}>Why Choose Our Services?</h3>
        <div className={styles.skills_grid_div}>
          {Customers.map(({ id, source,percent, name, colour }) => (
            <div  className={styles.skills__skilldiv} key={id} style={{ boxShadow: `0 4px 6px -1px ${colour}, 0 2px 4px -2px ${colour}` }}>
              <img style={{width:"99%"}} src={source} alt="skills" />
              <p><span style={{fontWeight:"bolder",fontFamily:"sans-serif",fontSize:"20px"}}>{percent}{" "}</span>{name}</p>
            </div>
          ))}
        </div>
      </div>
      {/* ----------------------------------------- */}
      <h1 className={styles.skill_heading}>Websites</h1>
      <p className={styles.skill__description} style={{marginBottom:"10px"}}>Currently optimzing these websites products. </p>


      <div style={{paddingBottom:"20px"}} className={styles.skills__secondmaindiv}>
        <div className={styles.skills_grid_div}>
          {SkillsArr.map(({ id, source, name, colour }) => (
            <div data-aos="zoom-in-up" className={styles.skills__skilldiv} key={id} style={{ boxShadow: `0 4px 6px -1px ${colour}, 0 2px 4px -2px ${colour}` }}>
              <img src={source} alt="skills" />
              <p>{name}</p>
            </div>
          ))}
        </div>
      </div>

     
    </section>


  )
}

export default Skills;