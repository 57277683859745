import { useState } from "react"
import { Navbar } from "./Navbar"
import imageCompression from "browser-image-compression"
export const Adddreams=()=>{
 // Add posts

const[title,setName]=useState("")
const[body,setEmail]=useState("")
const[image,setPassword]=useState("")
const[mediatordega,setMediatordega]=useState("")
const[humdenge,setHumdenge]=useState("")
const[hamaracommision,sethamaraComimision]=useState("")
const[orderform,setorderform]=useState("")
const[refundform,setrefundForm]=useState("")
const[dealType,setDealType]=useState("")

const hadlesubmit=(e)=>{
    e.preventDefault()
   
    if(title && body && image && mediatordega && humdenge && hamaracommision && previewImage && dealType){
        const payload={
          ProductName: title,
          Price: body,
          MedName: image,
          MedDega: mediatordega,
          HumDenge: humdenge,
          OrderForm: orderform,
          Hamaracommision:hamaracommision,
          RefundForm: refundform,
          DealType: dealType,
          TotalShared:0,
          LastShared:new Date().toISOString(),
          image:previewImage
        }
        fetch("https://dreams2.onrender.com/posts/add",{
            method:"POST",
            headers: {
                // "Authorization":localStorage.getItem("token"),
                "Content-Type": "application/json",
              },
              body:JSON.stringify(payload)
        })
        .then(res=>res.json())
        .then(res=>alert(res.msg))
        .catch(err=>console.log(err))
    }else{
        alert("fill all details")
    }

}
// Add posts End
const [previewImage, setPreviewImage] = useState(null);

const handleFileChange = async (e) => {
  const file = e.target.files[0];
  if (file) {
    try {
      const options = {
        maxSizeMB: 0.8,
        maxWidthOrHeight: 700,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(compressedFile);

     
    } catch (error) {
      console.error('Error compressing the image:', error);
    }
  }
};



    return(
      <div>
<Navbar/>
<div style={{"marginTop":"1.5rem","maxWidth":"28rem"}}>
  <div
  style={{"overflow":"hidden","borderRadius":"0.25rem","borderColor":"#2563EB"}}
  >
    <h3 style={{"marginTop":"2rem","textAlign":"center"}}>Add Product</h3>
    <div style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
    <input type="file" onChange={handleFileChange} />
      {previewImage && (
        <div>
          <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )}
      
    </div>
    <div style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="Product Name"
     style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setName(e.target.value)}}
      />
    </div>
   
    <div style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="price"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setEmail(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="Mediator Name"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setPassword(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="Mediator Dega"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setMediatordega(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="Hum Denge"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setHumdenge(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="hamara commision"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{sethamaraComimision(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="order form"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setorderform(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="refund form"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setrefundForm(e.target.value)}}
      />
    </div>
    <div style={{ paddingLeft: "1rem", paddingRight: "1rem", marginBottom: "1rem" }}>
  <select
   
    style={{ padding: "0.75rem", borderRadius: "0.25rem", borderWidth: "1px", width: "100%" }}
    onChange={(e) => { setDealType(e.target.value) }}
  >
    <option value="">Select Deal Type</option>
    <option value="Orignal Review Deal">Orignal Review Deal</option>
    <option value="Orignal Review/Rating Deal">Orignal Review/Rating Deal</option>
    <option value="Orignal Rating Deal">Orignal Rating Deal</option>
    <option value="EmptyBox Deal">Empty Box Deal</option>
    <option value="Exchange Review Deal">Exchange Review Deal</option>
    <option value="Exchange Review/Rating Deal">Exchange Review/Rating Deal</option>
    <option value="Exchange Rating Deal">Exchange Rating Deal</option>
    
    {/* Add more options as needed */}
  </select>
</div>
   
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <button
        style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1rem","paddingRight":"1rem","borderRadius":"0.25rem","borderWidth":"1px","borderColor":"#3B82F6","width":"100%","fontWeight":600,"color":"#ffffff","backgroundColor":"#2563EB"}}
        onClick={(e)=>{hadlesubmit(e)}}
      >
       Add to dreams
      </button>
    </div>
    
  </div>
</div>
      </div>

    )
}