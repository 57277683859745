// AllRoutes.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './components/home/HomePage';
import { Admin } from './Admin/Admin';
import { Posts } from './Admin/components/Posts';
import { Adddreams } from './Admin/components/Adddreams';
import { Signup } from './Admin/components/Signup';
import { Login } from './Admin/components/Login';
import { Patch } from './Admin/components/Patch';


const AllRoutes = () => {
  return (
  
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<Posts />} />
        {/* <Route path="/" element={<Posts/>}/> */}
        <Route path="/add" element={<Adddreams/>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/update/:postid" element={<Patch/>}/>
      </Routes>
    
  );
};

export default AllRoutes;
