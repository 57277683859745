import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "./Navbar";

export const Patch = () => {
    const navigate=useNavigate()
    const [image, setImage] = useState("")
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const[mediatordega,setMediatordega]=useState("")
const[humdenge,setHumdenge]=useState("")
const[hamaracommision,sethamaraComimision]=useState("")
const[orderform,setorderform]=useState("")
const[refundform,setrefundForm]=useState("")
const[dealType,setDealType]=useState("")
    let params = useParams();
   
  const getProduct=()=>{
    fetch(`https://dreams2.onrender.com/posts/${params.postid}`, {
        // headers: {
        //     "Authorization":localStorage.getItem("token"),
        //   }
      })
        .then((res) => res.json())
        .then((res) => {
            setTitle(res.ProductName)
                setBody(res.Price)
                setImage(res.MedName)
            setMediatordega(res.MedDega)
            setHumdenge(res.HumDenge)
            sethamaraComimision(res.Hamaracommision)
            setorderform(res.OrderForm)
            setrefundForm(res.RefundForm)
            setDealType(res.DealType)
            setPreviewImage(res.image)
        })
  
        .catch((err) => console.log(err));
  } 
    const handleSubmit = (e) => {

        
    e.preventDefault()
    if(title && body && image){
        const postDetails =  {
            ProductName: title,
            Price: body,
            MedName: image,
            MedDega: mediatordega,
            HumDenge: humdenge,
            OrderForm: orderform,
            Hamaracommision:hamaracommision,
            RefundForm: refundform,
            DealType: dealType,
            TotalShared:0,
            LastShared:0,
            image:previewImage
          }
            axios.patch(`http://localhost:8080/posts/update/${params.postid}`, postDetails, {
            headers: {
            "content-type": "application/json",
            // Authorization: localStorage.getItem("token"),
            },
            })
            .then((res) => {
            alert(res.data.msg)
            setTimeout(() => {
                navigate("/")
            }, 3000);
            }).catch((err) => {
            alert("not authorized user")
            })
    }else{
        alert("fill all details")
    }
   
    }
    const [previewImage, setPreviewImage] = useState(null);

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  }
};
useEffect(()=>{
getProduct()
},[params])
    return(
        <div>
<Navbar/>
     <div style={{"marginTop":"1.5rem","maxWidth":"28rem"}}>
        <div   style={{"overflow":"hidden","borderRadius":"0.25rem","borderColor":"#2563EB"}}>
        
   
        <div style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
    <input type="file" onChange={handleFileChange} />
      {previewImage && (
        <div>
          <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )}
      
    </div>
    <div style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="Product Name"
        value={title}
     style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setTitle(e.target.value)}}
      />
    </div>
   
    <div style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        value={body}
        placeholder="price"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setBody(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        value={image}
        placeholder="Mediator Name"
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setImage(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="Mediator Dega"
        value={mediatordega}
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setMediatordega(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="Hum Denge"
        value={humdenge}
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setHumdenge(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="hamara commision"
        value={hamaracommision}
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{sethamaraComimision(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="order form"
        value={orderform}
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setorderform(e.target.value)}}
      />
    </div>
    <div  style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <input
        type="text"
        placeholder="refund form"
        value={refundform}
        style={{"padding":"0.75rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%"}}
        onChange={(e)=>{setrefundForm(e.target.value)}}
      />
    </div>
    <div style={{ paddingLeft: "1rem", paddingRight: "1rem", marginBottom: "1rem" }}>
  <select
    value={dealType}
    style={{ padding: "0.75rem", borderRadius: "0.25rem", borderWidth: "1px", width: "100%" }}
    onChange={(e) => { setDealType(e.target.value) }}
  >
    <option value="">Select Deal Type</option>
    <option value="Orignal Review Deal">Orignal Review Deal</option>
    <option value="Orignal Review/Rating Deal">Orignal Review/Rating Deal</option>
    <option value="Orignal Rating Deal">Orignal Rating Deal</option>
    <option value="EmptyBox Deal">Empty Box Deal</option>
    <option value="Exchange Review Deal">Exchange Review Deal</option>
    <option value="Exchange Review/Rating Deal">Exchange Review/Rating Deal</option>
    <option value="Exchange Rating Deal">Exchange Rating Deal</option>
    
    {/* Add more options as needed */}
  </select>
</div>
  
  <div style={{"paddingLeft":"1rem","paddingRight":"1rem","marginBottom":"1rem"}}>
      <button
        style={{"paddingTop":"0.75rem","paddingBottom":"0.75rem","paddingLeft":"1rem","paddingRight":"1rem","borderRadius":"0.25rem","borderWidth":"1px","borderColor":"#3B82F6","width":"100%","fontWeight":600,"color":"#ffffff","backgroundColor":"#2563EB"}}
        onClick={(e)=>{handleSubmit(e)}}
      >
      Done
      </button>
    </div>
   
        </div>
   
    
    </div>
        </div>
    )
    };
    