import { Link } from "react-router-dom"
import React, { useState } from "react";


export const Navbar=()=>{
   
    return(

      <div >
      <div style={{ display: 'flex',justifyContent:"space-around", alignItems: 'baseline', gap: '1rem',backgroundColor:"black" }}>
        <a
          href="/admin"
          style={{
            color: 'white',
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            borderRadius: '0.375rem',
            fontSize: '0.875rem',
            fontWeight: '500',
            backgroundColor: 'transparent',
            transition: 'background-color 0.2s'
          }}
          onMouseEnter={(e) => e.target.style.backgroundColor = '#4A5568'}
          onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
        >
          Products
        </a>

        <a
          href="/add"
          style={{
            color: '#D1D5DB',
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            borderRadius: '0.375rem',
            fontSize: '0.875rem',
            fontWeight: '500',
            transition: 'background-color 0.2s, color 0.2s'
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#4A5568';
            e.target.style.color = 'white';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = '#D1D5DB';
          }}
        >
          +Add Product
        </a>
      </div>
    </div>

    )
}