import React from 'react'
import Navbar from '../Navbar/Navbar'
import Home from './Home'
import About from '../about/About'
import Skills from '../skills/Skills'
import Project from '../Projects/Project'
import Stats from '../stats/Stats'
import Contact from '../contact/Contact'
export const HomePage=()=> {
  return (
    <div>
      <Navbar />
      <main>
        <Home />
        <About />
        <Skills />
        <Project />
        <Stats />
        <Contact />
      </main>
    </div>
  )
}


