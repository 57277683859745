import React from 'react'

const SocialLinks = () => {
  return (
    <div >
        <a href="https://www.linkedin.com/in/nitesh-chandrakar-9a94ab242/" target="_blank" rel="noreferrer" >
        <i class="uil uil-linkedin-alt"></i>
        </a>
        <a href="https://github.com/niteshchandrakar" target="_blank" rel="noreferrer" >
        <i class="uil uil-github-alt"></i>
        </a>
         <a href="https://twitter.com" target="_blank" rel="noreferrer" >
        <i class="uil uil-twitter-alt"></i>
        </a>
    </div>
  )
}

export default SocialLinks;